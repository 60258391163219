import { Routes, Route, BrowserRouter } from "react-router-dom";
import Map from "./pages/map";
import Sections from "./pages/sections";
import { ROUTES } from "./constants/constants";
import ObjectDetails from "./pages/objectDetails";
import { SectionDetailsWrapper } from "./utils/utils";
import Home from "./pages/home";
import { AppProvider } from "./store/AppContext";
import Table from "./pages/table";

function App() {
  return (
    <AppProvider>
    <div className="min-h-screen bg-white">
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.SECTIONS} element={<Sections />} />
          <Route path={ROUTES.SECTION_DETAILS} element={<SectionDetailsWrapper />} />
          <Route path={ROUTES.MAP} element={<Map />} />
          <Route path={ROUTES.OBJECT} element={<ObjectDetails />} />
          <Route path={ROUTES.MAP_WITH_SECTION} element={<Map />} />
          <Route path={ROUTES.MAP_WITH_SUBSECTION} element={<Map />} />
          <Route path={ROUTES.TABLE} element={<Table />} />
          <Route path={ROUTES.TABLE_WITH_SECTION} element={<Table />} />
        </Routes>
      </BrowserRouter>
    </div>
    </AppProvider>
  );
}

export default App;
