import { useCallback, useEffect, useMemo, useState } from "react";
import ReactMapGl, {
  Layer,
  NavigationControl,
  Source,
  ViewState,
  ViewStateChangeEvent,
} from "react-map-gl";
import "./style.css";
import { COLORS, MAP_STYLE_URL } from "../constants/constants";
import placemarkRed from "../assets/icons/placemark-red.png";
import placemarkBlue from "../assets/icons/placemark-blue.png";
import placemarkMix from "../assets/icons/placemark-mix.png";
import { Filters, GeoJSONFeature, MyGeoJSON } from "../types/section";
import PopupCard from "../components/complexElements/objects/popupCard";
import { useParams } from "react-router-dom";
import MapHeader from "../components/complexElements/mapHeader";
import ObjectList from "../components/complexElements/objects/objectList";
import { useAppContext } from "../store/AppContext";

const Map = () => {
  const { uniqueIdGeoData } = useAppContext();

  const { sectionTitle, subsectionTitle } = useParams();
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const [viewport, setViewport] = useState<ViewState>({
    latitude: 55.782078,
    longitude: 37.575186,
    zoom: 9,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  });

  const [selectedPlace, setSelectedPlace] = useState<GeoJSONFeature | null>(
    null
  );

  const [filters, setFilters] = useState<Filters>({
    section: "",
    subsection: "",
    district: "",
    organization: "",
    objectLabel: "",
  });

  useEffect(() => {
    if (sectionTitle) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        section: sectionTitle,
      }));
    }
  }, [sectionTitle]);

  useEffect(() => {
    if (subsectionTitle) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        subsection: subsectionTitle,
      }));
    }
  }, [subsectionTitle]);

  const handleClick = useCallback((event: any) => {
    const features = event.features;
    if (features && features.length > 0) {
      const feature = features[0] as GeoJSONFeature;
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
      }));
      if (feature && !feature.properties.point_count) {
        setSelectedPlace(feature);
      }
    } else {
      setSelectedPlace(null);
    }
  }, []);

  const handleViewportChange = useCallback((evt: ViewStateChangeEvent) => {
    const { viewState } = evt;
    setViewport(viewState);
  }, []);

  const filteredGeoData: MyGeoJSON = useMemo(() => {
    return {
      ...uniqueIdGeoData,
      features: uniqueIdGeoData.features.filter((feature) => {
        const { coordinates } = feature.geometry;
        const { subsection, subsubsection, district, title, address} = feature.properties;
        return (
          coordinates[0] !== 0 && coordinates[1] !== 0 &&
          (!filters.section || subsection === filters.section) &&
          (!filters.subsection || subsubsection === filters.subsection) &&
          (!filters.district || district === filters.district) &&
          (!filters.organization || title === filters.organization) &&
          (!filters.objectLabel || 
            title.toLowerCase().includes(filters.objectLabel.toLowerCase()) || 
            address.toLowerCase().includes(filters.objectLabel.toLowerCase()))
        );
      }),
    };
  }, [filters, sectionTitle, subsectionTitle]);

  return (
    <div style={{ display: "flex", height: "100vh", minWidth: "800px" }}>
      <div
        style={{ flex: "1 1 70%", display: "flex", flexDirection: "column" }}
      >
        <MapHeader filters={filters} setFilters={setFilters} total={uniqueIdGeoData.features.length - 1} count={filteredGeoData.features.length}/>
        <div
          style={{
            flexGrow: 1,
            borderRadius: "20px",
            overflow: "hidden",
            marginLeft: "80px",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <ReactMapGl
            {...viewport}
            mapStyle={MAP_STYLE_URL}
            onMove={handleViewportChange}
            mapboxAccessToken={mapboxToken}
            style={{ width: "100%", height: "100%" }}
            interactiveLayerIds={[
              "point-layer",
              "clusters",
              "unclustered-point",
            ]}
            onClick={handleClick}
            onLoad={(event) => {
              const map = event.target;
              map.loadImage(placemarkRed, (error, image) => {
                if (error) return;
                if (image) {
                  map.addImage("custom-marker-red", image);
                }
              });
              map.loadImage(placemarkBlue, (error, image) => {
                if (error) return;
                if (image) {
                  map.addImage("custom-marker-blue", image);
                }
              });
              map.loadImage(placemarkMix, (error, image) => {
                if (error) return;
                if (image) {
                  map.addImage("custom-marker-mix", image);
                }
              });
            }}
            onMouseEnter={(event) => {
              const map = event.target;
              if (event.features && event.features[0].layer?.id === "unclustered-point") {
                map.getCanvas().style.cursor = "pointer";
              }
            }}
            onMouseLeave={(event) => {
              const map = event.target;
              if (event.features && event.features[0].layer?.id === "unclustered-point") {
                map.getCanvas().style.cursor = "";
              }
            }}
          >
            <Source
              id="points"
              type="geojson"
              data={filteredGeoData}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={25}
            >
              <Layer
                id="clusters"
                type="circle"
                source="points"
                filter={["has", "point_count"]}
                paint={{
                  "circle-color": COLORS.MAIN_RED,
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    17,
                    100,
                    30,
                    750,
                    40,
                  ],
                  "circle-stroke-width": 2,
                  "circle-stroke-color": "#FFFFFF",
                }}
              />
              <Layer
                id="cluster-count"
                type="symbol"
                source="points"
                filter={["has", "point_count"]}
                layout={{
                  "text-field": "{point_count_abbreviated}",
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 12,
                  "text-offset": [0, 0],
                  "text-anchor": "center",
                }}
                paint={{ "text-color": "#FFFFFF" }}
              />
              <Layer
                id="unclustered-point"
                type="symbol"
                source="points"
                filter={["!", ["has", "point_count"]]}
                layout={{
                  "icon-image": [
                    "case",
                    ["==", ["get", "color"], "red"], "custom-marker-red",
                    ["==", ["get", "color"], "blue"], "custom-marker-blue",
                    "custom-marker-mix"
                  ],
                  "icon-size": 0.45
                }}
              />
            </Source>
            {selectedPlace && (
              <PopupCard
                selectedPlace={selectedPlace}
                onClose={() => setSelectedPlace(null)}
              />
            )}
            <NavigationControl />
          </ReactMapGl>
        </div>
      </div>
      <div className="pt-[140px] pl-[35px] pr-[35px]" style={{ flex: "1 1 30%", overflowY: "auto" }}>
      <table className="min-w-full border-collapse">
        <ObjectList objects={filteredGeoData.features}/>
        </table>
      </div>
    </div>
  );
};

export default Map;
