  import { FC } from "react";
  import { Section } from "../../../types/section";
  import SectionItem from "./sectionItem";

  interface ISectionTableWithLabelsProps {
    rows: number;
    columns: number;
    mainColor: string;
    sections: Section[];
    title: string;
    isActive?: boolean;
    customClassname?: string;
  }

  const SectionTableWithLabels: FC<ISectionTableWithLabelsProps> = ({
      rows,
      columns,
      mainColor,
      sections,
      title,
      isActive,
      customClassname
    }) => {
      const titleClassname = `text-${mainColor} text-[20px] font-mossport`;

      const getBgColor = () => {
        if (isActive === undefined) {
          return mainColor;
        }
        return isActive ? 'white' : 'backgroundGrey'
      }

      const backgroundClassname = `bg-${getBgColor()} ${isActive === undefined && 'bg-opacity-5'} ${isActive && 'shadow-xl'} p-[16px] rounded-[20px] ${customClassname}`
      
      const createArray = (length: number) =>
        Array.from({ length }, (_, i) => i + 1);
    
      const filledSections = [...sections];
      
      const tableRows = createArray(rows).map((rowIndex) => (
        <tr key={rowIndex}>
          {createArray(columns).map((colIndex) => {
            const sectionIndex = (rowIndex - 1) * columns + (colIndex - 1);
            const section = filledSections[sectionIndex];
            return (
              <td key={colIndex} className="">
                <SectionItem key={section.title} section={section} color={mainColor} />
              </td>
            );
          })}
        </tr>
      ));
    
      return (
        <div className={backgroundClassname}>  
          <div className={titleClassname}>{title.toUpperCase()}</div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <tbody>{tableRows}</tbody>
            </table>
          </div>
        </div>
      );
    };
    
    export default SectionTableWithLabels;