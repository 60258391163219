import React, { ChangeEvent } from 'react';
import { ReactComponent as Down } from '../../assets/icons/down.svg';

interface SelectorProps {
  options: string[];
  label: string;
  value: string;
  onChange: (selectedValue: string) => void;
  disabled?: boolean;
  className?: string;
}

const Selector: React.FC<SelectorProps> = ({ options, label, value, onChange, disabled = false, className }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  const sortedOptions = [...options].sort((a, b) => a.localeCompare(b));

  return (
    <div className={`${className} relative inline-block w-full`}>
      <select
        className={`w-full h-[34px] px-3 bg-white border border-black rounded-[6px] appearance-none focus:shadow-outline font-gothamPro text-[10px] font-normal ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        <option value="" className="font-gothamPro">{label}</option>
        {sortedOptions.map((option, index) => (
          <option key={index} value={option} className="font-gothamPro">
            {option}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
        <Down />
      </div>
    </div>
  );
};

export default Selector;
