import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/icons/logo-black.svg";
import HorizontalProgressBar from "../components/ui/HorizontalProgressBar";
import SectionTableWithLabels from "../components/complexElements/sections/sectionTableWithLabels";
import mapIcon from "../assets/images/mapIcon.png";
import { ROUTES } from "../constants/constants";
import { useState } from "react";
import { useAppContext } from "../store/AppContext";

const HeaderBlock: React.FC<{
  onHover: (title: string | undefined) => void;
}> = ({ onHover }: { onHover: (title: string | undefined) => void }) => {
  const { sections } = useAppContext();
  return (
    <div className="px-[58px] py-[20px] h-[360px] w-[220px] flex flex-col items-center">
      <div className="font-mossport w-[230px] p-[30px] bg-red-600 rounded-[20px] text-white leading-[1] max-w-screen-xl flex flex-col items-start">
        <div className="text-[47px] pb-[20px]">БЮДЖЕТ 2025</div>
        <div className="text-[86px]">
          {(
            sections.reduce((total, section) => {
              return total + section.cost;
            }, 0) / 1e9
          ).toFixed(2)}
        </div>
        <div className="text-[47px]">МЛРД РУБ.</div>
      </div>
      <div className="mt-[20px] w-full flex flex-col items-center">
        <HorizontalProgressBar
          title="Проектирование, строительство и благоустройство"
          percentage={sections[0].percentage}
          amount={sections[0].cost}
          color="mainRed"
          onHover={onHover}
        />
        <HorizontalProgressBar
          title="Оснащение, оборудование и эксплуатация"
          percentage={sections[1].percentage}
          amount={sections[1].cost}
          color="lightBlue"
          onHover={onHover}
        />
        <HorizontalProgressBar
          title="Обеспечение деятельности"
          percentage={sections[2].percentage}
          amount={sections[2].cost}
          color="darkBlue"
          onHover={onHover}
        />
      </div>
    </div>
  );
};

const Sections = () => {
  const { sections, loading } = useAppContext();

  const navigate = useNavigate();
  const [activeTitle, setActiveTitle] = useState<string>();
  const handleHover = (title: string | undefined) => {
    setActiveTitle(title);
  };
  const bgClassname = `relative bg-${
    activeTitle === undefined ? "white" : "backgroundGrey"
  }`;

  const getIsActive = (title: string) => {
    if (activeTitle === undefined) return undefined;
    return activeTitle === title;
  };

  if (loading) {
    return <div>Загружаем данные...</div>;
  }

  return (
    <div className={bgClassname}>
      <div className="absolute top-0 left-[70px]">
        <Logo
          className="cursor-pointer mt-[24px] h-[50px]"
          onClick={() => navigate(ROUTES.SECTIONS)}
        />
        <HeaderBlock onHover={handleHover} />
      </div>
      <div className="pl-[340px] pr-[80px] pt-[90px] flex flex-col">
        <SectionTableWithLabels
          title="Проектирование, строительство и благоустройство"
          mainColor="mainRed"
          rows={1}
          columns={5}
          sections={sections[0].subsections?.sort((a, b) => b.percentage - a.percentage) || []}
          isActive={getIsActive(
            "Проектирование, строительство и благоустройство"
          )}
          customClassname="w-[1030px]"
        />
        <div className="mb-[30px]" />
        <div className="flex ">
          <SectionTableWithLabels
            title="Оснащение, оборудование и эксплуатация"
            mainColor="lightBlue"
            rows={2}
            columns={4}
            sections={sections[1].subsections?.sort((a, b) => b.percentage - a.percentage) || []}
            isActive={getIsActive("Оснащение, оборудование и эксплуатация")}
          />
          <div className="pl-[30px]" />
          <div className="flex flex-col items-center">
            <SectionTableWithLabels
              title="Обеспечение деятельности"
              mainColor="darkBlue"
              rows={1}
              columns={1}
              sections={sections[2].subsections || []}
              isActive={getIsActive("Обеспечение деятельности")}
              customClassname="h-[300px]"
            />
            <img
              src={mapIcon}
              alt="Navigate to Map"
              className="cursor-pointer w-[215px] h-[250px] object-fill mt-4"
              onClick={() => navigate(ROUTES.MAP)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sections;
