import React, { useState } from "react";

interface ProgressBarProps {
  title: string;
  percentage: number;
  amount: number;
  color: string;
  onHover: (title: string | undefined) => void;
}

const HorizontalProgressBar: React.FC<ProgressBarProps> = ({
  title,
  percentage,
  amount,
  color,
  onHover,
}) => {
  const [active, setActive] = useState(false); 
  const outerBarHeight = 26;
  const innerBarHeight = outerBarHeight - 4; 
  const barClassname = `bg-${color} h-full rounded-full mt-3 max-w-[150px]`;
  const percentageClassname = `text-${color} text-[12px] absolute right-2 top-1/2 transform -translate-y-1/2`;

  const handleMouseEnter = () => {
    setActive(true);
    onHover(title);
  };

  const handleMouseLeave = () => {
    setActive(false);
    onHover(undefined);
  };

  return (
    <div className={`p-[22px] w-full min-w-[230px] font-mossport leading-[1] cursor-pointer rounded-[20px] ${active && 'bg-white shadow-xl'}`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <div className="text-[20px]">{title.toUpperCase()}</div>
      <div className="w-full bg-regularGrey rounded-full mt-3 h-[26px] relative">
        <div
          className={barClassname}
          style={{
            width: `${percentage < 10 ? 10 : percentage}%`,
            height: `${innerBarHeight}px`,
            margin: "2px",
            position: "absolute",
          }}
        />
        <div className={percentageClassname}>{percentage}%</div>
      </div>
      <div className="font-gothamPro text-[16px] mt-3">
        {amount.toLocaleString("ru-RU")} руб.
      </div>
    </div>
  );
};

export default HorizontalProgressBar;
