import { FC } from "react";
import CircularProgressBar from "../../ui/CircularProgressBar";
import { Section } from "../../../types/section";
import { useAppContext } from "../../../store/AppContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { getObjectDeclension } from "../../../utils/utils";

const SubsectionElement: FC<{ section: Section, color: string, fatherTitle: string }> = ({ section, color, fatherTitle }) => {
  const {geoData} = useAppContext();
  const navigate = useNavigate();

  const amountOfObjects: number = geoData.features.filter(el => el.properties.subsubsection === section.title).length;

  const handleArrowClick = () => {
    navigate(`${ROUTES.MAP}/${fatherTitle}/${section.title}`);
  };

  return (
    <div className="flex w-full cursor-pointer" onClick={handleArrowClick}>
      <div className="pr-[24px]">
        <CircularProgressBar
          size={75}
          percentage={section.percentage}
          grayStrokeWidth={4}
          activeColor={color}
        >
          <div className="font-mossport text-[22px] text-darkGrey">{section.percentage} %</div>
        </CircularProgressBar>
      </div>
      <div className="flex-col grid gap-2">
        <div className="font-mossport text-[20px]">
          {section.title.toUpperCase()}
        </div>
        <div className={`font-mossport text-[38px] text-${color}`}>
          {section.cost.toLocaleString()} РУБ.
        </div>
        {/* <div className="font-gothamPro font-thin text-[14px]">
          {amountOfObjects} {getObjectDeclension(amountOfObjects)}
        </div> */}
      </div>
    </div>
  );
};

export default SubsectionElement;
