import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { Section } from "../../../types/section";
import CircularProgressBar from "../../ui/CircularProgressBar";
import { ROUTES, SECTION_DETAILS } from "../../../constants/constants";

import "../style.css";
import { useNavigate } from "react-router-dom";
import SectionModal from "./sectionModal";

interface SectionItemProps {
  section: Section;
  color?: string;
}

const SectionItem: React.FC<SectionItemProps> = ({
  section,
  color = "mainRed",
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClickArrow = () => {
    setModalOpen(true);
    // if (SECTION_DETAILS.includes(section.title)) {
    //   setModalOpen(true);
    // } else {
    //   navigate(`${ROUTES.SECTIONS}/${section.title}`);
    // }
  };

  const percentage = section.percentage;
  const title = section.title;
  const cost = section.cost;

  const titleClassname = `font-mossport text-${color} text-[54px]`;

  return (
    <div
      onClick={handleClickArrow}
      className="section-item flex flex-col leading-[1] items-center w-[185px] h-[240px] cursor-pointer"
    >
      <div className="flex items-start justify-between w-full px-4 py-4">
        <div className="w-[130px] text-[12px] h-[50px] font-bold overflow-hidden break-words">
          {title}
        </div>
        <Arrow className="w-[20px] h-[20px] cursor-pointer" />
      </div>
      <div className="relative w-[160px] h-[160px] mb-2">
        <CircularProgressBar
          size={155}
          percentage={section.percentage}
          grayStrokeWidth={6}
          activeColor={color}
        >
          <div className={titleClassname}>
            {(cost / 1e9).toFixed(2).toLocaleString()}
          </div>
          <div className="text-[12px] font-gothamPro">млрд руб.</div>
          <div className="text-[12px] font-gothamPro pt-1">
            {percentage.toFixed(2)}%
          </div>
        </CircularProgressBar>
      </div>
      <div className="cursor-default">
        <SectionModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          section={section}
          color={color}
        />
      </div>
    </div>
  );
};

export default SectionItem;
