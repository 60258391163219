import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { MyGeoJSON, Section } from '../types/section';
import { parseGeoData, parseSections } from '../utils/data';

interface AppContextType {
  sections: Section[];
  setSections: React.Dispatch<React.SetStateAction<Section[]>>;
  geoData: MyGeoJSON;
  setGeoData: React.Dispatch<React.SetStateAction<MyGeoJSON>>;
  uniqueIdGeoData: MyGeoJSON;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sections, setSections] = useState<Section[]>([]);
  const [geoData, setGeoData] = useState<MyGeoJSON>({
    type: 'FeatureCollection',
    features: []
  });  // Инициализация дефолтным объектом
  const [uniqueIdGeoData, setUniqueIdGeoData] = useState<MyGeoJSON>({
    type: 'FeatureCollection',
    features: []
  });
  const [loading, setLoading] = useState<boolean>(true);

  const filterUniqueGeoData = (data: MyGeoJSON): MyGeoJSON => {
    const uniqueFeaturesMap = new Map<string, any>();

    data.features.forEach((feature) => {
      const objectId = feature.properties.objectId;
      const section = feature.properties.section;

      if (!uniqueFeaturesMap.has(objectId)) {
        uniqueFeaturesMap.set(objectId, { feature, sections: new Set<string>([section]) });
      } else {
        uniqueFeaturesMap.get(objectId).sections.add(section);
      }
    });

    const uniqueFeatures = Array.from(uniqueFeaturesMap.values()).map(({ feature, sections }) => {
      let color = 'mix';
      if (sections.size === 1) {
        const singleSection = sections.values().next().value;
        if (singleSection === 'Проектирование, строительство и благоустройство') {
          color = 'red';
        } else if (singleSection === 'Оснащение, оборудование и эксплуатация') {
          color = 'blue';
        }
      }

      return {
        ...feature,
        properties: {
          ...feature.properties,
          color
        }
      };
    });

    return {
      type: 'FeatureCollection',
      features: uniqueFeatures
    };
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      // Загружаем данные sections
      const sectionData = await parseSections('/data.xlsx');
      setSections(sectionData);

      // Загружаем данные GeoJSON
      const loadedGeoData = await parseGeoData('/data.xlsx');  // Используем путь к вашему файлу GeoJSON
      setGeoData(loadedGeoData);

      // Фильтруем данные для получения уникальных features с установленным цветом
      const uniqueGeoData = filterUniqueGeoData(loadedGeoData);
      setUniqueIdGeoData(uniqueGeoData);

      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <AppContext.Provider value={{ sections, setSections, geoData, setGeoData, uniqueIdGeoData, loading, setLoading }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
