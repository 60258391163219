import React, { ReactNode } from 'react';

interface BackgroundVideoProps {
  children: ReactNode;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ children }) => {
  return (
    <div className="relative w-full h-screen">
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src="/background.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default BackgroundVideo;
