import { useNavigate } from "react-router-dom";
import { GeoJSONFeature, MyGeoJSON } from "../../types/section";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ElementTable = ({ data }: { data: MyGeoJSON }) => {
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    navigate(`/КАРТА/ОБЪЕКТ/${id}`);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ overflowX: "auto", maxWidth: "1350px" }}
    >
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>Раздел</TableCell>
            <TableCell>Пункт</TableCell>
            <TableCell>Подпункт</TableCell>
            <TableCell>Адрес объекта</TableCell>
            <TableCell>Район</TableCell>
            <TableCell>Округ</TableCell>
            <TableCell>Объект/ организация</TableCell>
            <TableCell>Назначение</TableCell>
            <TableCell>Общая стоимость договора, руб.</TableCell>
            <TableCell>Обоснование</TableCell>
            <TableCell>Заявки</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.features.map((feature: GeoJSONFeature, idx) => (
            <TableRow
              key={idx}
              sx={{
                backgroundColor:
                  idx % 2 !== 1 ? "rgba(0, 0, 0, 0.04)" : "inherit",
              }}
              onClick={() => handleRowClick(feature.properties.objectId)}
              className="cursor-pointer"
            >
              <TableCell>{feature.properties.section}</TableCell>
              <TableCell>{feature.properties.subsection}</TableCell>
              <TableCell>{feature.properties.subsubsection}</TableCell>
              <TableCell>{feature.properties.address}</TableCell>
              <TableCell>{feature.properties.neighborhood}</TableCell>
              <TableCell>{feature.properties.district}</TableCell>
              <TableCell>{feature.properties.title}</TableCell>
              <TableCell>{feature.properties.purpose}</TableCell>
              <TableCell>
                {feature.properties.price.toLocaleString("Ru-ru")}
              </TableCell>
              <TableCell>{feature.properties.justification}</TableCell>
              <TableCell>{feature.properties.applications}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default ElementTable;
