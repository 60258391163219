import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES, SECTION_DETAILS } from "../constants/constants";
import placeholderImage from "../assets/images/placeholderImage.jpg";
import Map from "../pages/map";

import { useEffect } from "react";
import SectionDetails from "../pages/sectionDetails";

export const SectionDetailsWrapper = () => {
  const { sectionTitle } = useParams();

  if (sectionTitle && SECTION_DETAILS.includes(sectionTitle)) {
    return <SectionDetails />;
  } else {
    return <Navigate to={`${ROUTES.MAP}/${sectionTitle}`} />;
  }
};

export const  MapWithSectionWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopstate = (event: PopStateEvent) => {
      // Проверяем, если состояние существует и откуда пользователь пришел
      if (event.state && event.state.from === ROUTES.SECTIONS) {
        navigate(ROUTES.SECTIONS);
      }
    };

    // Заменяем текущее состояние истории с добавлением информации о предыдущем маршруте
    window.history.replaceState({ from: location.pathname }, '');

    // Добавляем обработчик события popstate
    window.addEventListener('popstate', handlePopstate);

    // Убираем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [navigate, location.pathname]);

  return (
      <Map />
  );
}

export const getImage = (url: string) => url || placeholderImage;

export function getObjectDeclension(count: number): string {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
      return 'объектов';
  }

  if (lastDigit === 1) {
      return 'объект';
  } else if (lastDigit >= 2 && lastDigit <= 4) {
      return 'объекта';
  } else {
      return 'объектов';
  }
}