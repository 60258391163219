import React, { useEffect, useState } from "react";
import { Popup } from "react-map-gl";
import { GeoJSONFeature } from "../../../types/section";
import ObjectCard from "./objectCard";

interface PopupCardProps {
  selectedPlace: GeoJSONFeature | null;
  onClose: () => void;
}

const PopupCard: React.FC<PopupCardProps> = ({ selectedPlace, onClose }) => {
  const [image, setImage] = useState('');
  
  if (!selectedPlace) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const imagesString = selectedPlace.properties.images;
 
    if (imagesString) {
      const images = JSON.parse(imagesString.toString());
      setImage(images[0]);
    } else {
      setImage('');
    }
  }, [])

 
  return (  
    <Popup
      longitude={selectedPlace.geometry.coordinates[0]}
      latitude={selectedPlace.geometry.coordinates[1]}
      closeOnClick={false}
      onClose={onClose}
      anchor="bottom"
    >
      <div className="p-[14px] w-[236px] flex items-start flex-col bg-white/90 backdrop-blur-3xl rounded-lg shadow-md font-mossport">
      <ObjectCard
        object={selectedPlace.properties}
        img={image}
      />
      </div>
    </Popup>
  );
};

export default PopupCard;
